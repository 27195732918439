import Image from 'next/image';

import type { FunctionComponent } from 'react';
import type { TypeImageLogoProps } from './types';

/**
 * ImageLogo
 */
export const ImageLogo: FunctionComponent<TypeImageLogoProps> = ({
  className = '',
  imageBase,
  imageAbove,
  heightAbove,
  widthAbove,
}: TypeImageLogoProps) => (
  <div
    className={`relative flex w-full items-center justify-center rounded-t-md ${className}`}
  >
    <Image
      src={imageBase}
      layout="fill"
      alt="Image Becasa"
      aria-label="image of becasa"
      objectFit="cover"
    />

    <Image
      src={imageAbove}
      height={heightAbove}
      width={widthAbove}
      alt="Logo BeCasa"
      aria-label="logo-becasa"
    />
  </div>
);

ImageLogo.displayName = 'ImageLogo';
